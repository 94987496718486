export const termsConsitions = `
<!DOCTYPE HTML>
<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
	<link href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap" rel="stylesheet" />
    <title>:: Terms & Conditions ::</title>
    <style>
	
        * {
            padding: 0;
            margin: 0;
        }

        html, body {
            height: 100%;
			font-family: Poppins,Helvetica,sans-serif;
        }

        body {
            color: #333;
            font-size: 14px;
            line-height: 1.4;
            padding: 0px;
        }

        p {
            margin-bottom: 15px;
        }

        ul, ol {
            padding: 0 0 8px 25px;
        }

            ul li, ol li {
                padding: 4px 0;
            }

        h3 {
            color: #000;
            font-size: 18px;
            margin: 4px 0;
        }

        h4 {
            color: #000;
            font-size: 16px;
            margin: 4px 0;
        }

        .container {
            padding: 0 15px 20px;
            margin: 0 auto;
            display: block;
        }

        .header-title {
            padding: 10px 0;
        }

        .title {
            color: #EE6801;
            font-size: 28px;
            font-weight: 800;
            margin: 0 0 10px;
            padding-top: 10px;
        }
        .terms p{
            font-size: 14px;
            color: #000;
        }
        .terms ul li{
            list-style-type: decimal;
            font-size: 14px;
            color: #000;
        }

        .terms ul li h2{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .terms ul li::marker{
            font-size: 20px;
            font-weight: 600;
        }
        .terms ul li ol li{
            list-style-type:decimal;
            font-size: 14px;
            color: #000;
        }

        .terms ul li ol li::marker{
            font-size: 14px;
            color: #000;
            font-weight: 300;
        }

        .terms ul li ol ol li{
            list-style-type:decimal;
            font-size: 14px;
            color: #000;
        }

        .terms ul li ol ol li::marker{
            font-size: 14px;
            color: #000;
            font-weight: 300;
        }
		
		.mobile{
		width:100%;
		float:left;
		position:fixed;
		top:0px;
		right:0px;
		z-index:99;
        display:none;
		}
		
		.mobile img{
		right: 0px;
position: fixed;
		}
		.newterms{
		position:relative;
		z-index:1200;}
    </style>
</head>
<body>
    <section class="terms">
	
        <div class="container newterms">
           <!-- <h1 class="title">Terms & Conditions</h1>-->
		   <p>Marengo Asia Healthcare Private Limited a Company incorporated under the provisions of the Companies Act 2013 together with its subsidiaries and network hospitals (referred to as “we”, us”, “Marengo Asia Hospitals”) is the author and publisher of the internet resource on the world wide web as well as other software and applications provided by Marengo Asia Hospitals.</p>
<p>We, provide services to all individuals accessing or using Marengo Asia Hospitals websites including mobile applications, together termed as website subject to the notices, terms, and conditions set forth in these terms and conditions (<b>"Terms and Conditions"</b>, <b>"Agreement"</b>), read with the Privacy Policy available here. The Users of the Services (jointly and severally referred to as <b>“you”</b> or <b>“User”</b> or <b>“Users”</b> in these terms and conditions). By accessing or browsing of the Website and using the Services (as defined below) you indicate your agreement to all the terms and conditions in this Agreement. If you disagree with any part of the Terms and Conditions, then you may discontinue access or use of the Website. Marengo Asia Hospitals provides the Services in partnership with its agents, affiliates, associates, representatives or other third parties (together referred to as “Partners”)</p>
<ul>
    <li>
        <h2>NATURE AND APPLICABILITY OF TERM</h2>
        <p>Please carefully go through these terms and conditions (“<b>Terms</b>”) and the privacy policy available at Marengo Asia Hospitals Website (“<b>Privacy Policy</b>”) before you decide to access the Website or avail the Services made available by us. These Terms and the Privacy Policy together constitute a legal agreement (“<b>Agreement</b>”) between you and Marengo Asia Hospitals in connection with your visit to the Website and your use of the Services (as defined below).</p>
        <p><b>The Agreement applies to you whether you are :</b></p>
        <ol>
            <li>A patient, his/her representatives/ attendant/ relative/ friend or affiliates (<b>“you”</b> or <b>“User”</b>); or</li>
            <li>A user of the Website (<b>“you”</b> or <b>“User”</b>).
                <br>
                <p>This Agreement applies to all services made available by Marengo Asia Hospitals on the Website.</p>
                <p>The Services may change from time to time, at the sole discretion of Marengo Asia Hospitals and the Agreement will apply to your visit to and your use of the Website to avail the Service, as well as to all information provided by you on the Website at any given point in time.</p>
                <p>This Agreement defines the terms and conditions under which you are allowed to use the Website and describes the manner in which we shall treat your account while you are registered as a member with us. If you have any questions about any part of the Agreement, feel free to contact us at helpdesk@marengoasia.com.</p>
                <p>By downloading or accessing the Website to use the Services, you irrevocably accept all the terms and conditions stipulated in this Agreement and agree to fully abide by them.</p>
                <p>This Agreement supersedes all previous oral and written terms and conditions (if any) communicated to you. By availing any Service, you signify your agreement and acceptance to this Agreement.</p>
                <p>We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time.</p>
                <p>You should read the Agreement at regular intervals. Your use of the Website following any such modification constitutes your agreement to follow and be bound by the Agreement so modified. Any additional terms and conditions, disclaimers, privacy policies and other policies applicable in general and/ or to specific areas of this Website or to particular Service are also considered as part of the Agreement.</p>
                <p>You acknowledge that you will be bound by this Agreement for availing any of the Services offered by us. If you do not agree with any part of the Agreement, please do not use the Website or avail any Services.</p>
                <p>Your access to use of the Website and the Services will be solely at the discretion of Marengo Asia Hospitals. The Agreement is published in compliance of, and is governed by the provisions of Indian law, including but not limited to:</p>
            </li>
            <li>The Indian Contract Act, 1872</li>
            <li>The (Indian) Information Technology Act, 2000, and</li>
            <li>The rules, regulations, guidelines and clarifications framed there under, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”), and the (Indian) Information Technology (Intermediaries Guidelines) Rules, 2011 (the “IG Rules”).</li>
        </ol>
    </li>
    <li>
        <h2>CONDITIONS OF USE</h2>
        <p>You must be 18 years of age or older to register, use the Services, or visit or use the Website in any manner. By registering, visiting and using the Website or accepting this Agreement, you represent and warrant to Marengo Asia Hospitals that you are 18 years of age or older, and that you have the right, authority and capacity to use the Website and the Services available through the Website, and agree to and abide by this Agreement.</p>
    </li>
    <li>
        <h2>END-USER ACCOUNT AND DATA PRIVACY</h2>
        <ol>
            <li>The terms “personal information” and “sensitive personal data or information” are defined under the SPI Rules, and are reproduced in the Privacy Policy.</li>
            <li>Marengo Asia Hospitals and its Partners may by the Services, collect information relating to the devices through which you access the Website, location from which you access and anonymous data of your usage. The collected information will be used only for improving the quality of the Services and to build new services.</li>
            <li>The Website allows Marengo Asia Hospitals and its Partners to have access to Users’ personal email or phone number, for the purpose of communication and analytics.</li>
            <li>The Privacy Policy sets out:
                <ol>
                    <li>The type of information collected from Users, including sensitive personal data or information;</li>
                    <li>The purpose, means and modes of usage of such information;</li>
                    <li>How and to whom Marengo Asia Hospitals C will disclose such information; and,</li>
                    <li>Other information mandated by the SPI Rules.</li>
                    <li>The User is expected to read and understand the Privacy Policy, so as to ensure that he or she has the knowledge of:
                        <br>
                        <ol>
                            <li>The fact that certain information is being collected;</li>
                            <li>The purpose for which the information is being collected;</li>
                            <li>The intended recipients of the information;</li>
                            <li>The nature of collection and retention of the information; and</li>
                            <li>The various rights available to such Users in respect of such information.</li>
                        </ol>
                    </li>
                </ol>
            </li>
            <li>Marengo Asia Hospitals and its Partners shall not be responsible in any manner for the authenticity or correctness of the personal information or sensitive personal data or information supplied by the User to Marengo Asia Hospitals or to any other person acting on behalf of Marengo Asia Hospitals or its Partners.</li>
            <li>The User is responsible for maintaining the confidentiality of the User’s account access information and password, if the User is registered on the Website. The User shall be responsible for all usage of the User’s account and password, whether or not authorized by the User. The User shall immediately notify Marengo Asia Hospitals of any actual or suspected unauthorized use of the User’s account or password. Although Marengo Asia Hospitals will not be liable for your losses caused by any unauthorized use of your account, you may be liable for the losses of Marengo Asia Hospitals or others due to such unauthorized use.</li>
            <li>If a User provides any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or Marengo Asia Hospitals has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Marengo Asia Hospitals has the right to discontinue the Services to the User at its sole discretion.</li>
            <li>It is your responsibility to keep your correct mobile number and email ID updated in the Website. All reminders and notifications will be sent to the account associated with this mobile number and/or email ID. Every time you change any contact information (mobile or email), we will send a confirmation. Marengo Asia Hospitals is not responsible for any loss or inconvenience caused due to your non-updation of your contact details</li>
            <li>Marengo Asia Hospitals and its Partners may use such information collected from the Users from time to time for the purposes of debugging customer support related issues.</li>
            <li>Marengo Asia Hospitals may now or in the future provide Services where you may be able to converse with Marengo Asia Hospitals, its authorized representatives, its doctors or similar stakeholders via online chat, text messages, whatsapp, telegram ,video call or voice call. When you choose this option, the records of such conversations, calls or exchanges may be recorded and stored in Marengo Asia Hospitals’s or its Partners servers. Such records are dealt with only in accordance with the terms of the Privacy Policy. Marengo Asia Hospitals and its Partners accept no liability if you choose to use such a facility to interact with doctors or clinicians or if the facility is not used in accordance with the foregoing.</li>
            <li>11.	You may be provided with a personal health record facility to add, modify, delete and manage your health related records and information. The specific terms relating to this are as below, without prejudice to the rest of these Terms and the Privacy Policy:
                <br>
                <ol>
                    <li>This facility is only enabled or created for you after you have signed up and explicitly accepted these Terms.</li>
                    <li>Information available in your Health Account is of two types:
                        <br>
                        <ol>
                            <li>Patient-created: Information generated and uploaded by you.</li>
                            <li>Marengo Asia Hospitals -created: Information generated by your consuming a medical service in Marengo Asia Hospitals like consult, diagnostic test, admission, etc.</li>
                            <li>Any Marengo Asia Hospitals - created information is provided on an as-is basis and Marengo Asia Hospitals makes no representation in connection therewith.</li>
                            <li>The accuracy, adequacy, reliability and liability of any Patient-created information generated or created by a User is the sole responsibility of the User. You fully indemnify and hold harmless Marengo Asia Hospitals and its Partners with respect to any inaccuracies, inadequacy, error, loss, problem, liability or any other issue arising out of any Patient-created information entered or accessed by the you or on your behalf on the App.</li>
                            <li>The personal health record facility is provided on a best-efforts as-is basis. While we strive to maintain the highest levels of service availability, Marengo Asia Hospitals or its Partners are not liable for any interruption that may be caused to your access of the Services.</li>
                            <li>Any health related reminder or notification service (like medication reminder, appointment reminder, etc.) that may form part of the Services is only a supplementary way of notifying or reminding you of actions to take related to your health. Marengo Asia Hospitals is not liable if for any reason a reminder or notification is not delivered to you or are delivered late or delivered incorrectly, despite its best efforts or if your health is impacted negatively due to the same.</li>
                            <li>Marengo Asia Hospitals uses industry–level security and encryption to your personal health record. However, Marengo Asia Hospitals cannot guarantee prevention of unauthorized access if you lose your login credentials or they are otherwise compromised. Please safeguard your login credentials and report any actual suspected breach of account to it.support2@marengoasia.com</li>
                            <li>If you access your dependents’ record through your personal health record by registering your dependents with your own personal health record, you are deemed to be responsible for the records of your dependents and all obligations that your dependent’s would have had had they maintained their own separate individual personal health record.</li>
                            <li>If you provide anyone else access to your personal health record, you are deemed to be responsible for all actions, access, transaction that that person takes related to your personal health record either on their own or independently. Marengo Asia Hospitals or its Partners are not responsible or liable for any action, transaction, loss, leakage or any other liability arising out of this.</li>
                        </ol>
                    </li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        <h2>LISTING CONTENT AND DISSEMINATING INFORMATION</h2>
        <ol>
            <li>Marengo Asia Hospitals collects, directly or indirectly, and displays on the Website, relevant information regarding the profile and practice of the Practitioners listed on the Website such as their specialisation, qualification, fees, location, visiting hours, and similar details. Marengo Asia Hospitals takes reasonable efforts to ensure that such information is updated at frequent intervals. Marengo Asia Hospitals cannot be held liable for any inaccuracies or incompleteness represented from it, despite such reasonable efforts.</li>
            <li>The Services provided by Marengo Asia Hospitals or any of its licensors or Partners are provided on an "as is" and “as available’ basis, and without any warranties or conditions (express or implied, including the implied warranties of merchantability, accuracy, fitness for a particular purpose, title and non-infringement, arising by statute or otherwise in law or from a course of dealing or usage or trade). Marengo Asia Hospitals and its Partners do not provide or make any representation, warranty or guarantee, express or implied about Website or the Services. Marengo Asia Hospitals and its Partners do not guarantee the accuracy or completeness of any content or information provided by Users on the Website. To the fullest extent permitted by law, Marengo Asia Hospitals and its Partners disclaim all liability arising out of the User’s use or reliance upon the Website, the Services, representations and warranties made by other Users, the content or information provided by the Users on the Website, or any opinion or suggestion given or expressed by Marengo Asia Hospitals or its Partners or any User in relation to any User or services provided by such User.</li>
            <li>The Website may be linked to the App of third parties, affiliates and business partners. Marengo Asia Hospitals has no control over, and not liable or responsible for content, accuracy, validity, reliability, quality of such Apps or made available by/through our Website. Inclusion of any link on the Website does not imply that Marengo Asia Hospitals endorses the linked site. User may use the links and these services at User’s own risk.</li>
            <li>Marengo Asia Hospitals and its Partners assume no responsibility, and shall not be liable for, any damages to, or viruses that may infect User’s equipment on account of User’s access to, use of, or browsing the Website or the downloading of any material, data, text, images, video content, or audio content from the Website. If a User is dissatisfied with the Website, User’s sole remedy is to discontinue using the Website.</li>
            <li>If Marengo Asia Hospitals determines through feedback or otherwise that you have provided fraudulent, inaccurate, incomplete or false information, Marengo Asia Hospitals reserves the right to immediately suspend your access to the Website or any of your accounts with Marengo Asia Hospitals and makes such declaration about suspension on the Website alongside your name as determined by Marengo Asia Hospitals for the protection of its business and in the interests of Users. You shall be liable to indemnify Marengo Asia Hospitals and its Partners for any losses incurred as a result of your misrepresentations or fraudulent feedback that has adversely affected Marengo Asia Hospitals, its Partners or its Users.</li>
        </ol>
    </li>
    <li>
        <h2>BOOKING APPOINTMENTS, DIAGNOSTIC TESTS, ORDERING MEDICINES AND INTERACTION WITH MEDICAL SERVICE PROVIDERS</h2>
        <ol>
            <li>While Marengo Asia Hospitals will try to ensure a confirmed appointment with a medical service provider (like doctor or diagnostic lab) for a User who requested an appointment on the Website Marengo Asia Hospitals or its Partners do not guarantee that the User will get a confirmed appointment. Further, Marengo Asia Hospitals and its Partners have no liability if such an appointment is confirmed but later cancelled, postponed or rescheduled by the hospital or medical service provider, or the medical service provider are not available as per the given appointment time.</li>
            <li>Marengo Asia Hospitals or its Partners may provide value added services which connect Users directly to the medical service providers via video call, voice call, text message, whatsapp, telegram online chat or other electronic means and the information exchanged between the User and the medical service provider is stored and used in accordance with the Privacy Policy. However, it is at the discretion of the User, to avail such Service if it is available.</li>
            <li>You understand and agree that any interactions and associated issues with other Users including but not limited to your health issues and your experiences is strictly between you and the other Users. You shall not hold Marengo Asia Hospitals or its Partners responsible for any such interactions and associated issues and Marengo Asia Hospitals will not be held responsible or liable for the same. For avoidance of doubt, the Services are not to provide any healthcare or medical advice or diagnosis and hence Marengo Asia Hospitals and its Partners are not responsible for any medical or other outcomes between you and the medical service providers you interact with, pursuant to any interactions on the Website. If you decide to engage with a medical service provider to provide medical services to you, you do so at your own risk. Marengo Asia Hospitals or its Partners shall not be responsible for any breach of service or service deficiency by any medical service provider you connect, engage or transact with using the Website. We advise you to perform your own investigation or get feedback prior to selecting a doctor or any other service provider available on the Website.</li>
            <li>Without prejudice to the generality of the above, Marengo Asia Hospitals or its Partners will not be liable for: any wrong medication or treatment quality being given by the doctor(s), diagnostic service provider(s), pharmacy(s), or any medical negligence on part of the any of the above;
                <br>
                <ol>
                    <li>any type of inconvenience suffered by the User due to a failure on the part of the medical service provider including doctor to make himself/herself available at the appointed time, no show by the doctor, inappropriate treatment, or similar difficulties;</li>
                    <li>cancellation or rescheduling of booked appointment or any variance in the fees charged;</li>
                    <li>any medical eventualities that might occur subsequent to using the services of a doctor, diagnostic lab services, pharmacy, whom the User has selected on the basis of the information available on the Website or with whom the User has booked an appointment or performed a transaction through the Website.</li>
                    <li>Further, Marengo Asia Hospitals shall not be liable, under any event, for any comments or feedback given by any of the Users in relation to the services provided by another User. All such feedback should be made in accordance with applicable law. The option of Users to give feedback remains at Marengo Asia Hospitals’ sole discretion and may be modified or withdrawn at its sole discretion. Marengo Asia Hospitals may moderate such feedback at any time. Marengo Asia Hospitals shall not be obliged to act in any manner to give effect to the content of Users’ feedback.</li>
                </ol>
            </li>
        </ol>
    </li>
    <li>
        <h2>ONLINE PAYMENT ON APP AND APP</h2>
        <ol>
            <li>The Website provide you the ability to pay online through a third party payment gateway for some of the Services available on the Website.</li>
            <li>If you choose to pay online, you may be directed to a third party payment gateway to enable processing of the payment. This transaction will be governed by the terms and conditions and privacy policy of the third party payment gateway. Marengo Asia Hospitals shall not be liable (a) if any transaction does not fructify or may not be completed or (b) for any failure on part of the bank or the credit card or the third party site or agency to perform any of its obligations or (c) in respect of any loss or damage arising directly or indirectly arising out of the decline or acceptance of authorization for any transaction, for any reason whatsoever.</li>
        </ol>
        <p><u><b>Cancellation and Refund Policy:</b></u></p>
        <p>The cancellation and refund policy is only applicable to all online payments made through Website for booking appointments.</p>
        <p>In case cancellation is initiation by end user:</p>
        <ol>
            <li>Before 24 hrs of Schedule Appointment Time- 100% of Consultation Fees will be refunded. However, please note that convenience fees will not be refunded.</li>
            <li>After Scheduled Appointment Time- neither Consultation Fees nor Convenience Fees will be refunded.
                <br>
                In case Cancellation is initiated by relevant hospital due to unavailability of Doctor:
            </li>
            <li>100% of consultation Fees and Convenience fees will be refunded to end user.</li>
        </ol>
        <p>At the time of cancellation, amount will be refunded via same source of payment and will be subject to the payment terms of the source only. Amount will be refunded within 7 working days.</p>
    </li>
    <li>
        <h2>NO DOCTOR PATIENT RELATIONSHIP NOT FOR EMERGENCY USE</h2>
        <br>
        <ol>
            <li>Some of the content, text, data, graphics, images, information, suggestions, guidance, and other material (collectively, “Information”) that may be available on the Website (including information provided in direct response to your questions or postings) may be provided by individuals in the medical profession. The provision of such Information does not create a licensed medical professional/patient relationship, between Marengo Asia Hospitals and you and does not constitute an opinion, medical advice, or diagnosis or treatment of any particular condition, but is only provided to assist you with locating appropriate medical care from a qualified practitioner.</li>
            <li>It is hereby expressly clarified that, the Information that you obtain or receive from Marengo Asia Hospitals, and its employees, contractors, partners, sponsors, advertisers, licensors or otherwise on the Website is for informational purposes only. In no event shall Marengo Asia Hospitals be liable to you or anyone else for any decision made or action taken by you in reliance on such information.</li>
            <li>The Services are not intended to be a substitute for getting in touch with emergency healthcare. If you are an User facing a medical emergency (either on your or a another person’s behalf), please contact an ambulance service, hospital, doctor or appropriate medical professional directly.</li>
        </ol>
    </li>
    <li>
        <h2>CONTENT OWNERSHIP AND COPYRIGHT CONDITIONS OF ACCESS</h2>
        <br>
        <ol>
            <li>The contents listed on the Website are:
                <br>
                <ol>
                    <li>User generated content, or</li>
                    <li>Belong to Marengo Asia Hospitals and its Partners.</li>
                    <li>The information that is collected by Marengo Asia Hospitals directly or indirectly from the Users shall belong to Marengo Asia Hospitals and its Partners. Copying of the copyrighted content published by Marengo Asia Hospitalsor its Partners on the Website for any commercial purpose or for the purpose of earning profit will be a violation of copyright and Marengo Asia Hospitals and its Partners reserve their rights to take action under applicable law accordingly.</li>
                </ol>
            </li>
            <li>Marengo Asia Hospitals authorizes the User to view and access the content available on or from the Website solely for ordering, receiving, delivering, sharing, storing and communicating only as per this Agreement. The contents of the Website, information, text, graphics, images, logos, button icons, software code, design, and the collection, arrangement and assembly of content on the Website (collectively, "Marengo Asia Hospitals Content"), are the property of Marengo Asia Hospitals or its Partners and are protected under copyright, trademark and other applicable laws. User shall not modify the Marengo Asia Hospitals Content or reproduce, display, publicly perform, distribute, or otherwise use the Marengo Asia Hospitals Content in any way for any public or commercial purpose or for personal gain.</li>
            <li>User shall not access the Services for purposes of monitoring their availability, performance or functionality, or for any other benchmarking or competitive purposes.</li>
        </ol>
    </li>
    <li>
        <h2>REVIEWS AND FEEDBACK</h2>
        <br>
        <p>By using this Website you agree that any information shared by you with Marengo Asia Hospitals or with any other person, medical service provider or anyone else using the Website or Services will be subject to our Privacy Policy.</p>
        <p>You are solely responsible for the content that you choose to submit for publication on the Website, including any feedback, ratings, or reviews (“Critical Content”). The role of Marengo Asia Hospitals in publishing Critical Content is restricted to that of an ‘intermediary’ under the Information Technology Act, 2000. Marengo Asia Hospitals disclaims all responsibility with respect to the content of Critical Content, and its role with respect to such content is restricted to its obligations as an ‘intermediary’ under the said Act. Marengo Asia Hospitals shall not be liable to pay any consideration to any User for re-publishing any content across any of its or its Partners platforms.</p>
        <p>Your publication of reviews and feedback on the Website is governed by Clause 10 stated hereinafter. Without prejudice to the detailed terms stated in Clause 10, you hereby agree not to post or publish any content on the Website that (a) infringes any third-party intellectual property or publicity or privacy rights, or (b) violates any applicable law or regulation, including but not limited to the IG Rules and SPI Rules. Marengo Asia Hospitals, at its sole discretion, may choose not to publish your reviews and feedback, if so required by applicable law, and in accordance with Clause of these Terms. You agree that Marengo Asia Hospitals may contact you through telephone, email, SMS, whatsapp, telegram or any other electronic means of communication for the purpose of:</p>
        <ol>
            <li>Obtaining feedback in relation to Website, or Marengo Asia Hospitals’ services; and/or</li>
            <li>Obtaining feedback in relation to any medical service providers on the Website; and/or</li>
            <li>Resolving any complaints, information, or queries by medical service providers regarding your Critical Content; and you agree to provide your fullest co-operation further to such communication by Marengo Asia Hospitals.</li>
        </ol>
    </li>
    <li>
        <h2>RIGHTS AND OBLIGATIONS RELATING TO CONTENT</h2>
        <br>
        <ol>
            <li>As mandated by Regulation 3(2) of the IG Rules, Marengo Asia Hospitals hereby informs Users that they are not permitted to host, display, upload, modify, publish, transmit, update or share any information that:
                <br>
                <ol>
                    <li>Belongs to another person and to which the User does not have any right to;</li>
                    <li>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                    <li>Harm minors in any way;</li>
                    <li>Infringes any patent, trademark, copyright or other proprietary rights;</li>
                    <li>Violates any law for the time being in force;</li>
                    <li>Deceives or misleads the addressee about the origin of such messages, whatsapp, telegram or communicates any information which is grossly offensive or menacing in nature;</li>
                    <li>Impersonate another person;</li>
                    <li>Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                    <li>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation.</li>
                </ol>
            </li>
            <li>Users are also prohibited from:
                <br>
                <ol>
                    <li>Violating or attempting to violate the integrity or security of the Website, or any Marengo Asia Hospitals Content;</li>
                    <li>Transmitting any information (including job posts, messages , whatsapp, telegram and hyperlinks) on or through the Website that is disruptive or competitive or prejudicial to the provision of Services by Marengo Asia Hospitals;</li>
                    <li>Intentionally submitting any incomplete, false or inaccurate information;</li>
                    <li>Making any unsolicited communications to other Users;</li>
                    <li>Using any engine, software, tool, agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search the Website;</li>
                    <li>Attempting to decipher, decompile, disassemble or reverse engineer any part of the Website;</li>
                    <li>Copying or duplicating in any manner any of the Marengo Asia Hospitals Content or other information available from the Website;</li>
                    <li>Framing or hot linking or deep linking any Marengo Asia Hospitals Content.</li>
                </ol>
            </li>
            <li>Marengo Asia Hospitals or its Partners, upon obtaining knowledge by itself or been brought to actual knowledge by an affected person in writing or through email signed with electronic signature about any such information (as mentioned above) generated by Users, or on being notified by the appropriate Government or its agency that the Website is being used by the User to commit any unlawful act and/or is being used in violation of Clauses above, shall be entitled to remove or disable access to the material or information that is in contravention of Clause 10. Marengo Asia Hospitals is entitled to act, as required by the IG Rules, within thirty six hours of obtaining such knowledge and, where applicable, work with Users to disable such information that is in contravention of applicable law. Marengo Asia Hospitals shall also be entitled to preserve such information and associated records for at least 90 (ninety) days for production to governmental authorities for investigation purposes.</li>
            <li>In case of non-compliance with any applicable laws, rules or regulations, or the Agreement (including the Privacy Policy) by a User, Marengo Asia Hospitals has the right to immediately terminate the access or usage rights of the User to the Website, and Services and to remove non-compliant information from the Website.</li>
            <li>Website may disclose or transfer User-generated information to its affiliates or governmental authorities in such manner as permitted or required by applicable law, and you hereby consent to such transfer. Website will comply with any duly-issued government or court directions to disable access to the User-generated information, should it be found to be illegal by a competent governmental authority.</li>
        </ol>
    </li>
    <li>
        <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
        <br>
        <ol>
            <li>The contents of the Website are protected by intellectual property laws of India including without limitation to trademark and copyright laws. Reproduction, retransmission, public and/or commercial use of any or all the material on the Website are prohibited. The logos, service marks and trademarks ("IP Marks") displayed on the Website are the property of Marengo Asia Healthcare Private Limited or its Partners or have been licensed to Marengo Asia Healthcare Private Limited or its Partners by the relevant owners for use. The User may use this material only as expressly authorized by Marengo Asia Healthcare Private Limited or its Partners and shall not copy, transmit or create derivative works of such material without express authorization from Marengo Asia Healthcare Private Limited or its Partners.</li>
            <li>The User acknowledges and agrees that they shall not upload, post, reproduce or distribute any content on or through the Website that is protected by copyright or other proprietary right of a third party, without obtaining the permission of the owner of such right. Any copyrighted or other proprietary content distributed on or through the Website with the consent of the owner must contain the appropriate copyright or other proprietary rights notice. The unauthorized submission or distribution of copyrighted or other proprietary content is illegal and could subject the User to personal liability or criminal prosecution. Nothing on the Website should be construed as granting, by implication, estoppels, or otherwise, any license or right to use any trademarks without written permission from Marengo Asia Hospitals.</li>
        </ol>
    </li>
    <li>
        <h2>TERMINATION</h2>
        <br>
        <ol>
            <li>Marengo Asia Hospitals reserves the right to suspend or terminate a User’s access to the Website and the Services with or without notice and to exercise any other remedy available under law where:
                <br>
                <ol>
                    <li>Such User breaches any terms and conditions of the Agreement;</li>
                    <li>A third party reports violation of any of its right as a result of your use of the Services;</li>
                    <li>Marengo Asia Hospitals is unable to verify or authenticate any information provide to Marengo Asia Hospitals by a User;</li>
                    <li>Marengo Asia Hospitals has reasonable grounds for suspecting any illegal, fraudulent or abusive activity on part of such User; or</li>
                    <li>Marengo Asia Hospitals believes in its sole discretion that User’s actions may cause legal liability for such User, other Users or for Marengo Asia Hospitals or its Partners or are contrary to the interests of the App.</li>
                </ol>
            </li>
            <li>Once temporarily suspended, indefinitely suspended or terminated, the User may not continue to use the Website under the same account, a different account or re-register under a new account. On termination of an account due to the reasons mentioned herein, such User shall no longer have access to data, messages, whatsapp, telegram, files and other material kept on the Website by such User. The User shall ensure that he/she/it has continuous backup of any medical services the User has rendered in order to comply with the User’s record keeping process and practices.</li>
        </ol>
    </li>
    <li>
        <h2>DISCLAIMER OF WARRANTIES
            <br>
            <ol>
                <li>All information available on the Website, (the “Information”) is provided on the condition that the User will make independent determination in respect of its accuracy, completeness or usefulness suitability prior to use or making any decision for any loss or damage in reliance hereof. Marengo Asia Hospitals and its Partners will not be responsible for the same. Further Marengo Asia Hospitals and its Partners will not be responsible or liable in any manner for any data added, provided, stored or managed by the User including all personally identifiable information</li>
                <li>The Information does not constitute an invitation or recommendation to take medical services from Marengo Asia Hospitals or its Partners nor is such Information a substitute for professional advice or solicitation in respect of medical services/ products or recommendation thereof. Marengo Asia Hospitals urges the Users to seek the advice of professionals, as appropriate, regarding the evaluation of any specific opinion, advice, product, service, or other Information.</li>
                <li>All information on the Website is provided to you "as is" without warranty of any kind either express or implied including, but not limited to implied warranties of merchantability and fitness for a particular purpose, title, non-infringement, security or accuracy. In no event shall Marengo Asia Hospitals or its Partners be liable for any special, direct, indirect or consequential damages or any damages whatsoever resulting from loss, whether in an action of contract, negligence or other tortuous action, arising out of or in connection with the use or performance of information. All information available on a hyper-link site and any third party is subject to the terms and conditions of the legal notices contained therein.</li>
            </ol>
        </h2>
    </li>
    <li>
        <h2>LIMITATION OF LIABILITY</h2>
        <br>
        <ol>
            <li>The information available on the Website could include inaccuracies or typographic errors. Marengo Asia Hospitals has endeavoured to ensure that all the information on the Website is correct, but Marengo Asia Hospitals neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data or information contained. Marengo Asia Hospitals makes no warranty, express or implied, concerning the Website and/or its contents and disclaims all warranties of fitness for a particular purpose and warranties of merchantability in respect of Services, including any liability, responsibility or any other claim, whatsoever, in respect of any loss, whether direct or consequential, to any user or any other person, arising out of or from the use of the information contained in the Website.</li>
            <li>In no event shall Marengo Asia Hospitals or its Partners be liable for any direct, indirect, punitive, incidental, special, consequential damages or any other damages resulting from: (a) the use or the inability to use the Services; (b) unauthorized access to or alteration of the User's transmissions or data; (c) any other matter relating to the services; including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the use or performance of the Website. Neither shall the Company be responsible for the delay or inability to use the Website , Services or any related services, the provision of or failure to provide services, or for any information, software, products, services and related graphics obtained through the App, or otherwise arising out of the use of the Website, whether based on contract, tort, negligence, strict liability or otherwise. Further, Marengo Asia Hospitals shall not be held responsible for non-availability or access to the Website during periodic maintenance operations or any unplanned suspension of access to the Website that may occur due to technical reasons or for any reason beyond Marengo Asia Hospitals control. The user understands and agrees that any material and/or data downloaded or otherwise obtained through the Website is done entirely at his/her own discretion and risk and the User himself/herself will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material and/or data. These limitations, disclaimer of warranties and exclusions apply without regard to whether the damages arise from (a) breach of contract, (b) breach of warranty, (c) negligence, or (d) any other cause of action, to the extent such exclusion and limitations are not prohibited by applicable law.</li>
        </ol>
    </li>
    <li>
        <h2>INDEMNITY</h2>
        <br>
        <p>User agrees to indemnify and hold harmless Marengo Asia Hospitals, its affiliates, officers, directors, employees, consultants, licensors, agents, representatives and Partners from any and all third party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) arising from User’s access to or use of Service, violation of this Agreement, or infringement, or infringement by any other User of his/her/its account, of any intellectual property or other right of any person or entity. Marengo Asia Hospitals will notify you promptly of any such claim, loss, liability, or demand, and in addition to your foregoing obligations, you agree to provide us with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage, or cost.</p>
    </li>
    <li>
        <h2>APPLICABLE LAW AND DISPUTE SETTLEMENT</h2>
        <br>
        <ol>
            <li>You agree that this Agreement and any contractual obligation between Marengo Asia Hospitals and User will be governed by the laws of India.</li>
            <li>Subject to the above Paragraph above, the courts at Gujrat shall have exclusive jurisdiction over any disputes arising out of or in relation to this Agreement, your use of the Website or the Services or the information to which it gives access.</li>
        </ol>
    </li>
    <li>
        <h2>CONTACT INFORMATION GRIEVANCE OFFICER</h2>
        <br>
        <ol>
            <li>If a User has any questions concerning the Company, the Website, this Agreement, the Services, or anything related to any of the foregoing, please write to us at helpdesk@marengoasia.com.</li>
            <li>In accordance with the Information Technology Act, 2000, and the rules made there under, if you have any grievance with respect to the Website or the Service, including any discrepancies and grievances with respect to processing of information, you can contact our Grievance Officer at:
                <br>
                <p>The Grievance Officer,</p>
                <p>IT Support Team</p>
                <p>Email:it.support2@marengoasia.com.</p>
                <p>In the event you suffer as a result of access or usage of our Website by any person in violation of Rule 3 of the IG Rules, please address your grievance to the above person.</p>
            </li>
        </ol>
    </li>
    <li>
        <h2>SEVERABILITY</h2>
        <br>
        <p>If any provision of the Agreement is held by a court of competent jurisdiction or arbitral tribunal to be unenforceable under applicable law, then such provision shall be excluded from this Agreement and the remainder of the Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms; provided however that, in such event, the Agreement shall be interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and intention of the excluded provision as determined by such court of competent jurisdiction or arbitral tribunal.</p>
    </li>
    <li>
        <h2>WAIVER</h2>
        <br>
        <p>No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or consent shall be in writing and signed by Marengo Asia Hospitals. Any consent by Marengo Asia Hospitals to, or a waiver by Marengo Asia Hospitals of any breach by you, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.</p>
    </li>
</ul>
        </div>
		<div class="mobile"><img src="https://patientapp.marengoasia.com/images/Globe.png" /></div>
    </section>
<script type="text/javascript" src="/bnith__O5JuJ9254qb3sA_f6XgekH6SzauNu5lYpik6yU0vPSS2M-6SlNrorEJxxp8CvmunpJt-tuqUVs4="></script> <script language="JavaScript" type="text/javascript">const _0x35e8=['visitorId','18127kSXadA','356575NPKVMA','7306axxsAH','get','657833TzFjkt','717302TQdBjl','34lMHocq','x-bni-rncf=1717402777333;expires=Thu, 01 Jan 2037 00:00:00 UTC;path=/;','61XMWbpU','cookie',';expires=Thu, 01 Jan 2037 00:00:00 UTC;path=/;','then','651866OSUgMa','811155xdatvf','x-bni-fpc='];function _0x258e(_0x5954fe,_0x43567d){return _0x258e=function(_0x35e81f,_0x258e26){_0x35e81f=_0x35e81f-0x179;let _0x1280dc=_0x35e8[_0x35e81f];return _0x1280dc;},_0x258e(_0x5954fe,_0x43567d);}(function(_0x5674de,_0xdcf1af){const _0x512a29=_0x258e;while(!![]){try{const _0x55f636=parseInt(_0x512a29(0x17b))+-parseInt(_0x512a29(0x179))*parseInt(_0x512a29(0x17f))+-parseInt(_0x512a29(0x183))+-parseInt(_0x512a29(0x184))+parseInt(_0x512a29(0x187))*parseInt(_0x512a29(0x17d))+parseInt(_0x512a29(0x188))+parseInt(_0x512a29(0x17c));if(_0x55f636===_0xdcf1af)break;else _0x5674de['push'](_0x5674de['shift']());}catch(_0xd3a1ce){_0x5674de['push'](_0x5674de['shift']());}}}(_0x35e8,0x6b42d));function getClientIdentity(){const _0x47e86b=_0x258e,_0x448fbc=FingerprintJS['load']();_0x448fbc[_0x47e86b(0x182)](_0x4bb924=>_0x4bb924[_0x47e86b(0x17a)]())[_0x47e86b(0x182)](_0x2f8ca1=>{const _0x44872c=_0x47e86b,_0xa48f50=_0x2f8ca1[_0x44872c(0x186)];document[_0x44872c(0x180)]=_0x44872c(0x185)+_0xa48f50+_0x44872c(0x181),document[_0x44872c(0x180)]=_0x44872c(0x17e);});}getClientIdentity();</script></body>

<script type="text/javascript">var _0xcaad=["indexOf","; path=/","cookie","=","x-bni-ja","stack","phantomjs","plugins","length","onmousemove"];var err;function indexOfString(_0x1ce0x3,_0x1ce0x4){return _0x1ce0x3[_0xcaad[0]](_0x1ce0x4)}try{null[0]()}catch(e){err=e};function setCookie(_0x1ce0x6){var _0x1ce0x7=-2058049334;var _0x1ce0x8=1585157565;var _0x1ce0x9=_0x1ce0x7+_0x1ce0x8+_0xcaad[1];document[_0xcaad[2]]=_0x1ce0x6+_0xcaad[3]+_0x1ce0x9}function set_answer_cookie(){setCookie(_0xcaad[4])}function set_answer_cookie_1(){set_answer_cookie()}if((indexOfString(err[_0xcaad[5]],_0xcaad[6])> -1)||(!(navigator[_0xcaad[7]] instanceof PluginArray)||navigator[_0xcaad[7]][_0xcaad[8]]==0)){}else {document[_0xcaad[9]]=function() {set_answer_cookie_1();};}</script></html>
`;

export const refundPolicy = `
<!DOCTYPE HTML>
<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
	<link href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap" rel="stylesheet">
    <title>Refund Policy of MARENGO ASIA HOSPITALS</title>
    <style>
        * {
            padding: 0;
            margin: 0;
        }
		html, body {
				height: 100%;
				font-family: Poppins,Helvetica,sans-serif;
			}
        body {
            color: #333;
            font-size: 14px;
            line-height: 1.4;
            padding: 0px;
        }

        a, a:hover, a:focus {
            color: #EE6801;
            text-decoration: none;
        }

        p {
            margin-bottom: 15px;
        }

        ul, ol {
            padding: 0 0 8px 25px;
        }

        ul {
            list-style: circle;
        }

            ul li, ol li {
                padding: 4px 0;
            }

        h3 {
            color: #000;
            font-size: 22px;
            margin: 4px 0;
        }

        h3 {
            color: #000;
            font-size: 18px;
            margin: 4px 0;
        }

        h4 {
            color: #000;
            font-size: 16px;
            margin: 4px 0;
        }

        .container {
            padding: 0 15px 20px;
            margin: 0 auto;
            display: block;
        }

        .header-title {
            padding: 10px 0;
        }

        .title {
            color: #33398f;
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 10px;
            padding-top: 10px;
			line-height: 32px;
        }
		.privacy p{
            font-size: 14px;
            color: #000;
        }
        .privacy ul li{
            list-style-type:disc;
            font-size: 14px;
            color: #000;
        }

        .privacy ul li h2{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .privacy ul li::marker{
            font-size: 	18px;
            font-weight: 400;
        }
        .privacy ul li ol li{
            list-style-type:lower-alpha;
            font-size: 14px;
            color: #000;
        }

        .privacy ul li ol li::marker{
            font-size: 14px;
            color: #000;
            font-weight: 300;
        }

        .privacy ul li ol ol li{
            list-style-type:lower-roman;
            font-size: 14px;
            color: #000;
        }

        .privacy ul li ol ol li::marker{
            font-size: 14px;
            color: #000;
            font-weight: 300;
        }
		
		.mobile{
		width:100%;
		float:left;
		position:fixed;
		top:0px;
		right:0px;
		z-index:99;
		}
		
		.mobile img{
		right: 0px;
position: absolute;
		}
		.newterms{
		position:relative;
		z-index:1200;}
    </style>
</head>
<body>
    <section class="privacy">
        <div class="container newterms">
            <h1 class="title">Refund Policy of MARENGO ASIA HOSPITALS</h1>
			<p>Marengo Asia Healthcare Private Limited a Company incorporated under the provisions of the Companies Act 2013 together with its subsidiaries and network hospitals (referred to as “we”, us”, “Marengo Asia Hospitals”) is the author and publisher of the internet resource on the world wide web as well as other software and applications provided by Marengo Asia Hospitals.</p>
            <h3>DESCRIPTION OF SERVICES</h3>
            <p><strong>All visitors to our Website including Mobile Application together termed as “Website” should adhere to the following terms and conditions. BY ACCESSING OR USING THIS WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ACCEPT THEM IN FULL, AS THEY MAY BE MODIFIED BY  MARENGO ASIA HOSPITALS FROM TIME-TO-TIME AND POSTED ON THIS WEBSITE.</strong>
            </p>
            <p>
                <strong>Applicability:</strong> These website terms and condition are applicable to all transactions including online appointment booking, bill payments, advance payment against services and/or any other transactions through this website or through its hyperlink(s) and authorized third party websites.
            </p>
            <h3>Information provided by you:</h3>
            <ul>
                <li>We require you to provide us with certain information about yourself when you use the Online Transactions. This information shall include patient’s details and card holder’s name in case of online payment facility. We may also use this information to ensure that the bill payment process is carried out accurately and efficiently.</li>
                <li>You agree to ensure that any information provided by you shall be complete and accurate. You shall not at any time provide us with information which is false, inaccurate, misleading, obsolete or deceptive. If the information is found false/ deceptive/inaccurate/misleading, MARENGO ASIA HOSPITALS holds the right to cancel the appointment/ transaction and refund/stop the payment.</li>
                <li>All information provided by you should be true, complete and accurate but not misleading or deceptive;</li>
                <li>To receive the confirmation alert of the appointment booking, kindly provide your correct contact number/mobile number and email ID. By providing the said information, you hereby authorise MARENGO ASIA HOSPITALS to contact you via, telephonic calls, SMS/whatsapp/telegram and/ or electronic mail, in respect of the appointments booked by you and for providing the information about the services provided by MARENGO ASIA HOSPITALS.</li>
                <li>The consultation time provided is indicative and actual consultation time may vary due to exceptional circumstances beyond control of MARENGO ASIA HOSPITALS.</li>
                <li>One Appointment booking is only for one time consultation and not for follow consultation.</li>
                <li>Complete details of ‘New’ patient should be filled accurately and we may also use this information for data analysis and marketing purposes. By accepting these Terms, you expressly permit MARENGO ASIA HOSPITALS to use the data provided by you for the said purpose.</li>
                <li>We track the Internet address of the domains from which people visit us for trend analysis.</li>
                <li>We may use the Internet address to identify any fraudulent transaction. If the information provided by you is false/deceptive/inaccurate or misleading, we reserve the right to cancel your appointment without assuming any liability whatsoever. Hence, it’s advised to submit/upload all relevant and accurate information on the portal.</li>
                <li>If you do not wish to provide us with the required information, kindly click on exit button to exit the appointment booking application.</li>
            </ul>
            <h3>Availability</h3>
            <ul>
                <li>MARENGO ASIA HOSPITALS operates and controls this Website from India and makes no representations that the Information is appropriate for use in any other location across the globe. Information published in this Website may contain references to products and services which are available in India and not available in your country. Such references do not imply that MARENGO ASIA HOSPITALS intends to make available such products or services in your country. Users are urged to consult MARENGO ASIA HOSPITALS officials for information regarding the products and services available in your region.</li>
            </ul>
            <h3>LIMITATION OF LIABILITY FOR USE OF THE SITE</h3>
            <ul>
                <li>Despite best efforts, the information available on the website could include inaccuracies or typographic errors. MARENGO ASIA HOSPITALS specifically disclaims any liability for such inaccuracies or errors. MARENGO ASIA HOSPITALS does not warrant or represent that the information on the website is complete or up-to-date, and assumes no obligation to update the website. MARENGO ASIA HOSPITALS may change the information on the website at any time without notice or may make improvements or changes to the website at any time.</li>
                <li>You agree and undertake that MARENGO ASIA HOSPITALS, its affiliates and any of their respective officers, directors, employees, or agents will not be liable, whether in contract, tort, strict liability or otherwise, for any direct, punitive, special, consequential, incidental or indirect damages (including without limitation lost profits or lost opportunity) arising out of or in connection with the use of this website or hyper-link ,Link site or a third party site, or with delay or inability to use the website or a hyper-link Link site, even if MARENGO ASIA HOSPITALS  is made aware of the possibility of such damages. This limitation on liability includes, but is not limited to, transmission of any viruses which may infect a user's equipment, unauthorized access, operator errors, theft, failure of mechanical or electronic equipment or communication lines, telephone or other connect problems, strikes or other labour problems or any force majeure. MARENGO ASIA HOSPITALS cannot and does not guarantee continuous, uninterrupted or secure access to the Website.</li>
                <li>You are informed that use of internet may be susceptible to a number of frauds, misuse, hacking and other actions that could affect payment instructions / other instructions to your bank through the payment gateway. MARENGO ASIA HOSPITALS shall aim to provide security to prevent the same, but there cannot be any guarantee from such internet frauds, hacking and other actions that could affect payment instructions / other instructions including result in delay or failure in processing the instructions. MARENGO ASIA HOSPITALS shall not be responsible for the same.</li>
                <li>MARENGO ASIA HOSPITALS shall not be responsible for any such failed transaction, where the bank account of the user of the website is debited for the transaction amount and the transaction is not completed, for any reason whatsoever. Such user should connect with his/her banker for any such failed transactions and the user agrees to absolves MARENGO ASIA HOSPITALS of any liability, whatsoever, on this account.</li>
            </ul>
            <h3>DATA PROTECTION</h3>
            <ul>
                <li>MARENGO ASIA HOSPITALS   may use "Cookies" for storing visitor preferences, profiling visitors and tracking visitor behaviour on this website. Cookies are small data files that a website stores on computer. By visiting this website you acknowledge, accept and expressly authorize MARENGO ASIA HOSPITALS   for the placement of Cookies on your computer.</li>
                <li>MARENGO ASIA HOSPITALS   shall take all reasonable precautions to preserve the confidentiality and prevent any corruption or loss, damage or destruction of the data and information provided by you on the website.</li>
                <li>All personal medical information will be kept confidential. It will only be disclosed to those involved with your treatment or care or persons involved in the process of evaluation of your information including but not limited to medical treatment, procedure, services etc. or an organization, which may be responsible to evaluate your treatment expenses. However, MARENGO ASIA HOSPITALS   reserves the right to perform statistical analyses on your personal data and information. If such data has been requisitioned by any government agency/department/body and/or any law enforcement agency, MARENGO ASIA HOSPITALS   shall be entitled to share the same on receipt of a written notice in this regard.</li>
                
				<li>Certain services on the website may require authentication procedures and enable you to access such services by using a user ID and password. MARENGO ASIA HOSPITALS   shall take reasonable care to ensure the security of and to prevent unauthorized access to the services, which are part of the website, however you will be the sole and exclusive owner of user ID and the password. It is your responsibility to ensure the confidentiality and protection of your user ID and password and will not reveal to any third party. You are urged to change your password at regular intervals. In addition to user ID and password, MARENGO ASIA HOSPITALS   may, at its discretion, require you to adopt such other means of authentication.</li>
				
                <li>You are cautioned that use of cyber-cafe/shared computer for payment transaction at a shared terminal is risky and you are urged not to use a cyber-cafe/shared computer terminal for any such transactions.</li>
            </ul>
            <h3>Gateway Security</h3>
            <ul>
                <li>We wish to make your payment process a secure and convenient experience, and invite you to carefully read the Terms and Conditions which govern the use of the Online Payment Facility.</li>
                <li>We use all reasonable endeavours to ensure the integrity of the Online Payment Facility’s Security. Despite our endeavours breaches of security and confidentiality could occur. You acknowledge that we are not liable for any loss suffered by you as a result of any breaches in security.
                </li>
                <li>You are responsible for keeping the computer you are making the payment from ,free from viruses and malicious programs, and maintaining all appropriate security measures.</li>
                <li>You acknowledge that your failure to do so may lead to, amongst other things, the interception of data by unauthorized parties. We shall have no liability to you whatsoever if you suffer any loss that is caused wholly or in part by your failure to keep secure the computer you are making a payment from.</li>
                <li>MARENGO ASIA HOSPITALS   may at any time modify the Terms & Conditions of Use of the site without any prior notification to you. You can access the latest version of the User Agreement at any given time on the website. You should regularly review the Terms & Conditions on the website. In the event the modified Terms & Conditions is not acceptable to you, you should discontinue using the service. However, if you continue to use the service you shall be deemed to have agreed to accept and abide by the modified Terms & Conditions of Use of this site.</li>
            </ul>
            <h3>Payment</h3>
            <ul>
                <li>When MARENGO ASIA HOSPITALS   receives a transaction instruction from patients/customers in respect of any service on the website, through appropriate mode you may also be directed to a third party site to enable processing of a transaction in which event such transaction will be governed by the terms and conditions of such third party site. MARENGO ASIA HOSPITALS   shall not be liable (a) if any transaction comes in error or may not be completed or (b) for any failure on part of the bank or the credit card or the third party site or agency to perform any of its obligations or (c) in respect of any loss or damage arising directly or indirectly out of the decline or acceptance of authorization for any transaction, for any reason whatsoever.</li>
            </ul>
            <h3>Payment Gateway & Methods:</h3>
            <ul>
                <li>MARENGO ASIA HOSPITALS   online payments are redirected to the service provider for processing and then return back to our site.</li>
				
                <li>We do not consider that a payment has been made successfully until Invoice No/Advance receipt no is issued. If you do not receive the same, you should process your payment again or make your payment by another means.</li>
               
                <li>In case you experience an error message or service interruption after submitting payment information, it is your responsibility to confirm the same from your bank or with the Customer Service Department whether or not your payment has been made. Only you may be aware of any problems that may occur during the payment process. MARENGO ASIA HOSPITALS   will not be responsible for losses (monetary or otherwise) if you are aware that payment was not made because you failed to receive confirmation.in that case you can go for new transaction. In case of Credit/Debit Card bookings the Credit/Debit Card and Card holder must be present at the billing counter.</li>
            </ul>
            <h2>Confirmation/ Evidence of Transaction:</h2>
            <h3>Paid Appointments</h3>
            <ul>
                <li>SMS/whatsapp//Email confirmation - Your booking and payment confirmation will be sent via SMS/whatsapp/email given by you at the time of booking. For OPD appointments, Invoice for all paid OPD appointments would be generated automatically in real time basis. The SMS/whatsapp/email/Invoice copy need to be presented on the hospital counter at the time of hospital visit, along with patient photo id proof.
                </li>
				<li>In respect of any transactions permitted by MARENGO ASIA HOSPITALS   via its website from time to time, MARENGO ASIA HOSPITALS’s own records of such transactions, maintained through computer systems or otherwise, including the recording of the time of the transaction(s), shall be conclusive proof of the genuineness and accuracy of such transactions and shall be accepted as conclusive evidence and binding on you for all purposes.</li>

            </ul>
            <h3>Important</h3>
            <ul>
                <li>If you are unable to make your appointment MARENGO ASIA HOSPITALS   may attempt to contact you, using the information provided at the time of booking. If MARENGO ASIA HOSPITALS   is unable to reach you after its initial attempt, MARENGO ASIA HOSPITALS   may entertain another walk in /next appointee patient.</li>
                <li>You will need to produce the credit card/ debit card used for the payment of the booking at the OPD billing Counter.</li>
            </ul>
            <h3>Pricing and Other Errors</h3>
            <ul>
                <li>If the amount paid by you for an appointment, Preventive Health Check (PHP) and OP/IP Deposit varies from the actual services charge because of change in price or you are able to book an appointment or PHP before the scheduled OPD date or you are able to book an appointment or PHP that was not supposed to have been released for booking then; MARENGO ASIA HOSPITALS   will have the right to cancel that appointment and refund you the amount that you have paid. This will apply regardless of human error or a transactional malfunction of this Website.</li>
            </ul>
            <h2>CANCELLATION & REFUND POLICY</h2>
            <h3>Cancellations:</h3>
            <h3>Cancellation by the Customer-</h3>
            <ul>
                <li>For any online paid appointments/PHP, where services were not availed at the appointed time despite invoices being generated against such online appointment booking, you should cancel such invoice for claiming a refund through online cancellation option, before 24 hours of booked appointment slot.</li>
				<li>In case of appointment booking time slot, fall under 24 hours you will not be able to cancel through offline or online mode and claim refund.</li>
				<li>Cancellation by MARENGO ASIA HOSPITALS   – In the event of OPDs/Appointment get cancelled by MARENGO ASIA HOSPITALS   or postponed by the Doctor, we will attempt to contact you and refund the amount as per the policy of the MARENGO ASIA HOSPITALS   and you may book new appointment as per your convenience or visit  <a href="https://www.marengoasiahospitals.com/" target="_blank">https://www.marengoasiahospitals.com/ </a>  for new booking on the Website.</li>
            </ul>
            <h3>Refunds:</h3>
            <ul>
                <li>Upon receipt of a valid cancelation request of the online payment against appointment, PHP and/or IP Deposit as stated above, we will refund the money the way the payment was received. If the payment was made by Credit/ Debit Card or Net-Banking, we will refund through the Credit/Debit Card or Net-Banking account. Typically refunds are processed in7 working days. Please note that we shall not be responsible for any delays in credit to the Cardholder's credit card account/ accountholder’s bank account as that is managed by the Cardholder's issuing bank.</li>
                <li>You will be provided with refund reference number for further communication with your bank.</li>
                <li>No refunds / cancellation requests shall be entertained in case of payment against bills / Services received.</li>
                <li>Under any circumstances, cash would not be refunded against cancellation.</li>
            </ul>
            <h3>General Terms and Conditions :</h3>
            <ul>
                <li>This agreement shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the courts of Haryana.</li>
                <li>If any part of the agreement is determined to be invalid or unenforceable pursuant to applicable law then the said provision will be superseded by a valid, enforceable provision and the remainder of the agreement shall continue in effect.</li>
                <li>You acknowledge that the relationship created through your use of this website shall be of principal to principal. You shall not represent yourself as a representative agent or employee of MARENGO ASIA HOSPITALS  </li>
                <li>You represent and warrant that you’re not a minor (i.e., you are above 18 years of age) and that you are competent and eligible to enter into legally binding agreement.</li>
            </ul>
        </div>
		<div class="mobile"><img src="https://patientapp.marengoasia.com/images/Globe.png" /></div>
    </section>
</body>
<script type="text/javascript">var _0xcaad=["indexOf","; path=/","cookie","=","x-bni-ja","stack","phantomjs","plugins","length","onmousemove"];var err;function indexOfString(_0x1ce0x3,_0x1ce0x4){return _0x1ce0x3[_0xcaad[0]](_0x1ce0x4)}try{null[0]()}catch(e){err=e};function setCookie(_0x1ce0x6){var _0x1ce0x7=-2058049334;var _0x1ce0x8=1617788219;var _0x1ce0x9=_0x1ce0x7+_0x1ce0x8+_0xcaad[1];document[_0xcaad[2]]=_0x1ce0x6+_0xcaad[3]+_0x1ce0x9}function set_answer_cookie(){setCookie(_0xcaad[4])}function set_answer_cookie_1(){set_answer_cookie()}if((indexOfString(err[_0xcaad[5]],_0xcaad[6])> -1)||(!(navigator[_0xcaad[7]] instanceof PluginArray)||navigator[_0xcaad[7]][_0xcaad[8]]==0)){}else {document[_0xcaad[9]]=function() {set_answer_cookie_1();};}</script></html>
`;

export const privacyPolicy = `
<!DOCTYPE HTML>
<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
	<link href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap" rel="stylesheet">
    <title>::Privacy Policy ::</title>
    <style>
        * {
            padding: 0;
            margin: 0;
        }

        html, body {
            height: 100%;
			font-family: Poppins,Helvetica,sans-serif;
        }

        body {
            color: #333;
            font-size: 14px;
            line-height: 1.4;
            padding: 0px;
        }

        p {
            margin-bottom: 10px;
        }

        ul, ol {
            padding: 0 0 8px 15px;
        }

            ul li, ol li {
                padding: 4px 0;
            }

        h3 {
            color: #000;
            font-size: 18px;
            margin: 4px 0;
        }

        h4 {
            color: #000;
            font-size: 16px;
            margin: 4px 0;
        }

        .container {
            padding: 0 15px 20px;
            margin: 0 auto;
            display: block;
        }

        .header-title {
            padding: 10px 0;
        }
      
        .title {
            color: #EE6801;
            font-size: 28px;
            font-weight: 800;
            margin: 0 0 10px;
            padding-top: 10px;
        }
        .privacy p{
            font-size: 14px;
            color: #000;
        }
        .privacy ul li{
            list-style-type: decimal;
            font-size: 14px;
            color: #000;
        }

        .privacy ul li h2{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        .privacy ul li::marker{
            font-size: 20px;
            font-weight: 600;
        }
        .privacy ul li ol li{
            list-style-type:lower-alpha;
            font-size: 14px;
            color: #000;
        }

        .privacy ul li ol li::marker{
            font-size: 14px;
            color: #000;
            font-weight: 300;
        }

        .privacy ul li ol ol li{
            list-style-type:lower-roman;
            font-size: 14px;
            color: #000;
        }

        .privacy ul li ol ol li::marker{
            font-size: 14px;
            color: #000;
            font-weight: 300;
        }
		
		.mobile{
		width:100%;
		float:left;
		position:fixed;
		top:0px;
		right:0px;
		z-index:99;
        display:none;
		}
		
		.mobile img{
		right: 0px;
position: absolute;
		}
		.newterms{
		position:relative;
		z-index:1200;}
    </style>
</head>
<body>
    <section class="privacy">
        <div class="container newterms">
           <!-- <h1 class="title">Privacy Policy</h1>-->
			<p>Marengo Asia Healthcare Private Limited a Company incorporated under the provisions of the Companies Act 2013 together with its subsidiaries and network hospitals (referred to as “we”, us”, “Marengo Asia Hospitals”) is the author and publisher of the internet resource on the world wide web as well as other software and applications provided by Marengo Asia Hospitals. Marengo Asia Hospitals provides the Services in partnership with its representatives, associates, agents, affiliates, or other third parties (together referred to as “Partners”)</p>
			<p>This privacy policy ("Privacy Policy") explains how we collect, use, share and protect personal information about the Users of the Services (jointly and severally referred to as “you” or “User” or “Users” in this Privacy Policy). We are fully committed to the protection of your privacy and your personal information as available with Marengo Asia Hospitals. Your use of and access to the Services is subject to this Privacy Policy and our Terms of Use. Any capitalized term used but not defined in this Privacy Policy shall have the meaning attributed to it in our Terms of Use.<p>
             <p>BY CONFIRMING THAT YOU ARE BOUND BY THIS PRIVACY POLICY (BY THE MEANS PROVIDED ON THIS WEBSITE INCLDUING MOBILE APPLICATION TERMED AS “WEBSITE”), BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU AGREE TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY, YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF.</p>
           
            <ul>
                <li>  <h2>WHY THIS PRIVACY POLICY?</h2>
                       <p>This Privacy Policy is published in compliance with, inter alia:</p>
                
                <ol>
                    <li>Section 43A of the Information Technology Act, 2000; </li>
                    <li>Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the “SPI Rules”); and</li>
                    <li>Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
                
                <h4>This Privacy Policy States The Following:</h4>
                <ol>
                    <li>The type of information collected from the Users, including sensitive personal data or information;</li>
                    <li>The purpose, means and modes of usage of such information; and</li>
                    <li>How and to whom we will disclose such information.</li>
                </ol>
            </ol>
            </li>

            <li>
                <h2>CONDITIONS OF USE</h2>
                <p>In order to better knowing the User of our Website; when you access the Services, we may ask you to voluntarily provide us with certain information that personally identifies you or could be used to personally identify you. Without prejudice to the generality of the above, information collected by us from you may include (but is not limited to) the following:</p>
            
                <ol>
                    <li>Contact data (such as name, address, your email address and phone number);</li>
					<li>Profile picture (optional) </li>
                    <li>Demographic data (such as your gender, your date of birth and your pin code);</li>
                    <li>Data regarding your usage of the services and history of the appointments and other transactions made by or with you through the use of Services;</li>
                    <li>Health or medical data (such as your past medical history and conditions, diagnostic reports (including images in whatever mode or form), prescriptions and medication history)</li>
                    <li>Insurance data (such as your insurance carrier and insurance plan); and</li>
                    <li>Other information that you voluntarily choose to provide to us (such as your correspondence address details, family details, educational qualifications, work details etc.)</li>
					<li>Whatsapp profile</li>
                </ol>

                <p>The information collected from you by Marengo Asia Hospitals may constitute ‘personal information’ or ‘sensitive personal data or information’ under the SPI Rules. Personal information is defined under the SPI Rules to mean any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person.</p>
                <p>The SPI Rules further define “sensitive personal data or information” of a person to mean personal information about that person relating to:</p>
           
                <ol>
                    <li>passwords;</li>
                    <li>financial information such as bank accounts, credit and debit card details or other payment instrument details;</li>
                    <li>physical, physiological and mental health condition;</li>
                    <li>sexual orientation;</li>
                    <li>medical records and history;</li>
                    <li>biometric information;</li>
                    <li>information received by body corporate under lawful contract or otherwise;</li>
                    <li>visitor details as provided at the time of registration or thereafter; and</li>
                    <li>Call data records</li>
                </ol>
                <p>Information that is freely available in the public domain or accessible under the Right to Information Act, 2005 or any other law will not be regarded as personal information or sensitive personal data or information.</p>
            </li>

           <li>
            <h2>PRIVACY STATEMENTS</h2>
            <ol>
                <li>A condition of each User’s use of and access to the Services is their acceptance of the Terms of Use, which also involves acceptance of the terms of this Privacy Policy. Any User that does not agree with any provisions of the same has the option to discontinue use of the Services provided by Marengo Asia Hospitals immediately.</li>
                <li>All the information provided to us by a User, including sensitive personal information, is voluntary. You understand that Marengo Asia Hospitals, either itself or with its Partners, may use certain information of yours, which has been designated as ‘sensitive personal data or information’ under the SPI Rules, (a) for the purpose of providing you the Services, (b) for commercial purposes and in an aggregated or non- personally identifiable form for research, statistical analysis and business intelligence purposes, for (c) for  transfer of such research, statistical or intelligence data in an aggregated or non-personally identifiable form to our Partners. Marengo Asia Hospitals also reserves the right to use information provided by or about the User for the following purposes:
                <ol>
                    <li>Publishing such information on the Website.</li>
                    <li>Contacting Users for offering new products or services.</li>
                    <li>Contacting Users for taking product and Service feedback.</li>
                    <li>Analysing software usage patterns for improving product design and utility.</li>
                    <li>Analysing anonymized information for commercial use.</li>
                </ol>
              </li>
              <li>You hereby give your consent to such use of such information by Marengo Asia Hospitals and our Partner(s).</li>
			  <li>You hereby understand that profile picture, if uploaded by user will be visible to Marengo Asia Hospitals and our Partner(s).</li>
              <li>Collection of information which has been designated as ‘sensitive personal data or information’ under the SPI Rules requires your express consent. By affirming your assent to this Privacy Policy, you provide your consent to such collection as required under applicable law. Our Services may be unavailable to you in the event such consent is not given.</li>
              <li>Users’ personally identifiable information, which they choose to provide on the website is used to help the Users describe/identify themselves better. Other information that does not personally identify the Users as an individual, is collected by Marengo Asia Hospitals or our Partners from Users (such as, patterns of utilization described above) and is exclusively owned by Marengo Asia Hospitals or its partners. We or our Partners may also use such information in an aggregated or non-personally identifiable form for research, statistical analysis and business intelligence purposes, and may otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties. In particular, we and our Partners reserve with us the right to use anonymized User demographics information and anonymized User health information for the following purposes:  
                <ol>
                    <li>Analysing software usage patterns for improving product design and utility.</li>
                    <li>Analysing such information for research and development of new technologies.</li>
                    <li>Using analysis of such information in other commercial product offerings of Marengo Asia Hospitals or our Partners.</li>
                    <!--<li>Sharing analysis of such information with third parties for commercial use.</li>-->
                </ol>
              </li>
              <li>You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of account registration. If your personal information changes, you may correct, delete inaccuracies, or amend information by contacting us at helpdesk@marengoasia.com. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable. If you provide any information that is untrue, inaccurate, out of date or incomplete (or becomes untrue, inaccurate, out of date or incomplete), or Marengo Asia Hospitals has reasonable grounds to suspect that the information provided by you is untrue, inaccurate, out of date or incomplete, Marengo Asia Hospitals may, at its sole discretion, discontinue the provision of the Services to you.</li>
              <li>If you wish to cancel your account or request that we no longer use your information to provide you services, contact us through helpdesk@marengoasia.com. We will retain your information for as long as your account with the Services is active and as needed to provide you the Services. We shall not retain such information for longer than is required for the purposes for which the information may lawfully be used or is otherwise required under any other law for the time being in force. After a period of time, your data may be anonymized and aggregated, and then may be held by us or our Partners as long as necessary for us to provide our Services effectively or improve the Services, but our use of the anonymized data will be solely for analytic purposes.</li>
              <li>Marengo Asia Hospitals may require the User to pay with a credit card, debit card, net banking or other online payment mechanisms for Services for which an amount(s) is/are payable. Marengo Asia Hospitals will collect such User’s credit card number and/or other financial institution information such as bank account numbers and will use that information for the billing and payment processes, including but not limited to the use and disclosure of such credit card number and information to third parties as necessary to complete such billing operation. Verification of credit information, however, is accomplished solely by the User through the authentication process offered by a third party payment gateway. User’s credit card / debit card details are transacted upon secure sites of approved payment gateways which are digitally under encryption, thereby providing the highest possible degree of care as per current technology. User is advised, however, that internet technology is not full proof safe and User should exercise discretion on using the same.</li>
              <li>Due to the communication standards on the Internet, when a User or anyone who visits the website, we automatically receive the URL of the site from which anyone visits. We also receive the Internet Protocol (IP) address of each User’s computer (or the proxy server a User used to access the World Wide Web), User’s computer/ device operating system and type of web browser the User is using, email patterns, as well as the name of User’s ISP. This information is used to analyse overall trends to help Marengo Asia Hospitals improve its Services. The linkage between User’s IP address and User’s personally identifiable information may be available to us or our Partners but is not shared with other third parties. Notwithstanding the above, we may share some of the aggregate findings (not the specific data) in anonymized form (i.e., non-personally identifiable) with advertisers, sponsors, investors, strategic partners, and others in order to help grow our business.</li>
              <li>The Website may use cookies to store certain data (that is not sensitive personal data or information) that is used by us and our partners for the technical administration of the Website, research and development, and for User administration. In the course of serving advertisements or optimizing services to its Users, Marengo Asia Hospitals may allow authorized third parties to place or recognize a unique cookie on the User’s browser. The cookies however, do not store any personal information of the User.</li>
              <li>In order to have access to all the features and benefits on our Website, a User must first create an account on our website. To create an account, a User is required to provide the following information, which such User recognizes and expressly acknowledges is personal information allowing others, including Marengo Asia Hospitals, to identify the User: Name, User ID, E-mail address, Correspondence Address, Date of Birth, Gender, Contact Details and password chosen by the User. Other optional information may be requested on the registration page. We may, in future, include other optional requests for information from the User to help us to customize the Services to deliver personalized information to the User. However, we assume your consent in relation to various matters, once you complete the registration process.</li>
              <li>This Privacy Policy applies to website including mobile application and Services that are owned and operated by Marengo Asia Hospitals. We do not exercise control over the sites that may be displayed as search results or links from within the Services. These other sites may place their own cookies or other files on the Users’ computer, collect data or solicit personal information from the Users, for which Marengo Asia Hospitals is not responsible or liable. Accordingly, Marengo Asia Hospitals does not make any representations concerning the privacy practices or policies of such third parties or terms of use of such Applications, nor does Marengo Asia Hospitals guarantee the accuracy, integrity, or quality of the information, data, text, software, sound, photographs, graphics, videos, messages, whatsapp, telegram or other materials available on such Applications. The inclusion or exclusion does not imply any endorsement by Marengo Asia Hospitals of the Application, the Application's provider, or the information on the Applications. If you decide to visit a third party Application linked to our website, you do this entirely at your own risk. Marengo Asia Hospitals encourages the User to read the privacy policies of that Application.</li>
              <li>The Services may enable a User to communicate with other Users or to post information to be accessed by others, whereupon other Users may collect such data. Such Users, including any moderators or administrators, are not authorized representatives or agents of Marengo Asia Hospitals, and their opinions or statements do not necessarily reflect those of Marengo Asia Hospitals, and they are not authorized to bind Marengo Asia Hospitals to any contract. Marengo Asia Hospitals hereby expressly disclaims any liability for any reliance or misuse of such information that is made available by Users or visitors in such a manner.</li>
              <li>Marengo Asia Hospitals may periodically ask users to complete surveys asking about their experiences with features of the Applications and Services. Our surveys may ask visitors for demographic information such as age, gender, and education. We use survey information for evaluation and quality improvement purposes, including helping Marengo Asia Hospitals to improve information and services offered. In addition, users giving feedback may be individually contacted for follow-up due to concerns raised during the course of such evaluation. Demographic information and Web log data may be stored for future evaluation and quality improvement activities.</li>
              <li>Comments or questions sent to us using email or secure messaging forms will be shared with our employees and health care professionals who are most able to address the comment or question. We will archive your messages, whatsapp, and telegram once we have made our best effort to provide you with a complete and satisfactory response. Some of our services such as our automated appointment selection and prescription refill for Marengo Asia Hospitals generated prescriptions interact directly with other Marengo Asia Hospitals data systems. Data about your transaction may be stored in these systems, and available to people who test and support these systems. When you use a service on the Applications to interact directly with Marengo Asia Hospitals health care professionals, some information you provide may be documented in your medical record, and available for use to guide your treatment as a patient.</li>
              <li>Our Website may include social media Features, such as the Facebook, Twitter, YouTube, Instagram and LinkedIn buttons. These Features may collect your IP address, which page you are visiting on our Applications, and may set a cookie to enable the Feature to function properly. Your interactions with these Features are governed by the privacy statement of the company providing them.</li>
              <li>If you are using the Website, with your permission, we will use the geo-location feature of your device or same or similar feature of the device you are using to access the Website. Marengo Asia Hospitals and our Partners do not share your location information with other any third party. You may opt out of location based services on your device by changing the relevant/ applicable setting at your device level.</li>
              <li>Marengo Asia Hospitals does not collect information about the visitors of the Website from other sources, such as public records or bodies, or private organisations, save and except for the purposes of registration of the Users (the collection, storage and disclosure of which each User must agree to under the terms of use in order for Marengo Asia Hospitals to effectively render the Services).</li>
              <li>Marengo Asia Hospitals has implemented best international market practices and security policies, rules and technical measures to protect the personal data that it has under its control from unauthorised access, improper use or disclosure, unauthorised modification and unlawful destruction or accidental loss. However, for any data loss or theft due to unauthorized access to the User’s electronic devices through which the User avails the Services, Marengo Asia Hospitals or its Partners shall not be held liable for any loss whatsoever incurred by the User.</li>
              <li>Marengo Asia Hospitals ensures that it and its partners implement reasonable security practices and procedures that are commensurate with respect to the information being collected and the nature of Marengo Asia Hospitals’ business. The reasonable security practices and procedures implemented by Marengo Asia Hospitals include but are not limited to: encrypting data when it is on the move using industry standard practices, regularly changing production keys and password, secure access to all production servers, performing regular security updates on our servers and more.</li>
              <li>Marengo Asia Hospitals takes your right to privacy very seriously and other than as specifically stated in this privacy Policy, will only disclose your personal information in the event it is required to do so by law, rule, regulation, law enforcement agency, governmental official, legal authority or similar requirements or when Marengo Asia Hospitals, in its sole discretion, deems it necessary in order to protect its rights or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to enforce or apply the Terms of Use.</li>
              <li>Marengo Asia Hospitals may also disclose or transfer End-Users’ personal and other information which a User provides, to a third party as part of re-organization or sale of asset of Marengo Asia Hospitals. Any third party to which Marengo Asia Hospitals transfers or sells its assets, will have the right to continue to use the personal and other information that Users provide to us, in accordance with the Terms of Use.</li>
              <li>To the extent necessary to provide Users with the Services, Marengo Asia Hospitals may provide their personal information to third party contractors who work on behalf of or with Marengo Asia Hospitals to provide Users with such Services, to help Marengo Asia Hospitals communicate with Users or to maintain the Website. Generally these contractors do not have any independent right to share this information, however certain contractors who provide services on the Website, including the providers of online communications services, will have rights to use and disclose the personal information collected in connection with the provision of these Services in accordance with their own privacy policies.</li>
              <li>Notwithstanding the above, Marengo Asia Hospitals is not responsible for the confidentiality, security or distribution of your personal information by our Partners and third parties outside the scope of our agreement with such Partners and third parties. Marengo Asia Hospitals and its Partners shall not be responsible for any breach of security or for any actions of any third parties or events that are beyond the reasonable control of Marengo Asia Hospitals and its Partners including, acts of government, computer hacking, unauthorised access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of Internet service or telephone service of the User etc.</li>
              <li>No administrator at Marengo Asia Hospitals will have knowledge of your password. It is important for you to protect against unauthorized access to your password, your computer and your mobile phone or device. Be sure to log off from the application when finished. Marengo Asia Hospitals and its Partners do not undertake any liability for any unauthorized use of your account and password. If you suspect any unauthorized use of your account, you must immediately notify Marengo Asia Hospitals by sending an email to helpdesk@marengoasia.com. You shall be liable to indemnify Marengo Asia Hospitals, its employees and Partners due to any loss suffered by them due to such unauthorized e of your account and password.</li>
            </ol>
            <p>
                You acknowledge that this Privacy Policy is a part of the Terms of Use of the Website and the other Services, and you unconditionally agree that becoming a User of the Website and its Services signifies your assent to this Privacy Policy. Your visit to the Website, use of the Website and use of the Services is subject to this Privacy Policy and the Terms of Use.
            </p>
           </li>

        </div>
		<div class="mobile"><img src="https://patientapp.marengoasia.com/images/Globe.png" /></div>
    </section>
</body>
<script type="text/javascript">var _0xcaad=["indexOf","; path=/","cookie","=","x-bni-ja","stack","phantomjs","plugins","length","onmousemove"];var err;function indexOfString(_0x1ce0x3,_0x1ce0x4){return _0x1ce0x3[_0xcaad[0]](_0x1ce0x4)}try{null[0]()}catch(e){err=e};function setCookie(_0x1ce0x6){var _0x1ce0x7=-2058049334;var _0x1ce0x8=160146785;var _0x1ce0x9=_0x1ce0x7+_0x1ce0x8+_0xcaad[1];document[_0xcaad[2]]=_0x1ce0x6+_0xcaad[3]+_0x1ce0x9}function set_answer_cookie(){setCookie(_0xcaad[4])}function set_answer_cookie_1(){set_answer_cookie()}if((indexOfString(err[_0xcaad[5]],_0xcaad[6])> -1)||(!(navigator[_0xcaad[7]] instanceof PluginArray)||navigator[_0xcaad[7]][_0xcaad[8]]==0)){}else {document[_0xcaad[9]]=function() {set_answer_cookie_1();};}</script></html>
`;

export const consentPolicy = `
<!DOCTYPE HTML>
<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
	<link href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap" rel="stylesheet"><title>:: Virtual Consult Appointment Terms and Conditions for Patient ::</title>
    <style>
        * {
            padding: 0;
            margin: 0;
        }

         html, body {
            height: 100%;
			font-family: Poppins,Helvetica,sans-serif;
        }

        body {
            color: #333;
            font-size: 14px;
            line-height: 1.4;
            padding: 0px;
        }

        p {
            margin-bottom: 15px;
        }

        ul, ol {
            padding: 0 0 8px 15px;
        }

            ul li, ol li {
                padding: 4px 0;
            }

        h3 {
            color: #000;
            font-size: 18px;
            margin: 4px 0;
        }

        h4 {
            color: #000;
            font-size: 16px;
            margin: 4px 0;
        }

        .container {
            padding: 0 15px 20px;
            margin: 0 auto;
            display: block;
        }

        .header-title {
            padding: 10px 0;
        }

        .title {
             color: #33398f;
            font-size: 23px;
            font-weight: 800;
            margin: 0 0 10px;
            padding-top: 10px;
        }
		.mobile{
		width:100%;
		float:left;
		position:fixed;
		top:0px;
		right:0px;
		z-index:99;
		}
		
		.mobile img{
		right: 0px;
position: absolute;
		}
		.newterms{
		position:relative;
		z-index:1200;}
    </style>
</head>
<body>
    <section>
        <div class="container newterms">
            <h1 class="title">Virtual Consult Appointment Terms and Conditions for Patient</h1>

                <p>By using Marengo Asia Hospitals Website including Mobile Application together termed as Website Teleconsultation you hereby confirm that:</p>
                <ol>
                    <li>You are above 18 years of age and are competent to consult a Doctor or Specialist on your own accord without any supervision (referred to as the Registered Medical Practitioner “RMP”). In the event that you are consulting the RMP for a minor or disabled patient, you represent that you are the family member, guardian and / or authorised by the patient to represent the patient (“Caregiver”).</li>
                    <li>You accept that the virtual consultation may only be allowed by the RMP, in case of a minor or disabled patient, if he / she is consulting along with his / her Caregiver.</li>
                    <li>You have provided complete and valid information (including regarding your identity) uploaded via the Marengo Asia Hospitals Website virtual platform for the RMP. You shall also provide additional information including medical history, allergies and all relevant information, as may be required by RMP in his / her professional judgement or relevant to your medical condition.</li>
					
                    <li>You are aware that the virtual consultation is NOT for medical emergencies, life threatening conditions or for when you need acute care. You need to contact the hospital in such situations.</li>
					
                    <li>You accept that the RMP shall have the sole discretion on judge whether to diagnose and/or to prescribe basis the information available via the virtual consultation.</li>
					
                    <li>You accept that the diagnosis or prescription provided by the RMP is solely based on the declaration made by you via the information provided to the RMP during the virtual consultation, including the doctors  notes or documents uploaded by you. If you provide any inaccurate information or supress or fail to provide any pertinent / necessary information to RMP, which leads to misdiagnosis/incomplete diagnosis by the RMP, you hereby accept that the RMP / Hospital shall not be held responsible for the same.</li>
					
                    <li>You shall be responsible to follow the instruction for the diagnosis provided by the RMP. Should you disregard the RMP’s advice, the RMP / Hospital shall not be held liable for any resultant consequences thereof in any form or manner whatsoever.</li>
					
                    <li>You should seek follow-up care when recommended by the RMP or when you are of the opinion that such care is prudent in your sole opinion.</li>
                    <li>Without prejudice to the above, RMP may, in his or her sole discretion and/or professional judgment, determine that the virtual consultation is not appropriate for some or all of your medical or clinical needs and, accordingly, may elect not to provide services to you via the virtual consultation.</li>
					
					<li>Further you agree that you have the right to discontinue the virtual consultation at any stage.</li>
                    <li>You are aware that the virtual consultation is being recorded and will be managed as per the policy of the Hospital </li>
					<li>By confirming your request for a tele consult appointment with the RMP, you hereby consent to seek consultation from the selected RMP 
						via Marengo Asia Hospitals App.</li>   
             </ol>
        </div>
		<div class="mobile"><img src="https://patientapp.marengoasia.com/images/Globe.png" /></div>
    </section>
</body>
<script type="text/javascript">var _0xcaad=["indexOf","; path=/","cookie","=","x-bni-ja","stack","phantomjs","plugins","length","onmousemove"];var err;function indexOfString(_0x1ce0x3,_0x1ce0x4){return _0x1ce0x3[_0xcaad[0]](_0x1ce0x4)}try{null[0]()}catch(e){err=e};function setCookie(_0x1ce0x6){var _0x1ce0x7=-2058049334;var _0x1ce0x8=1095044179;var _0x1ce0x9=_0x1ce0x7+_0x1ce0x8+_0xcaad[1];document[_0xcaad[2]]=_0x1ce0x6+_0xcaad[3]+_0x1ce0x9}function set_answer_cookie(){setCookie(_0xcaad[4])}function set_answer_cookie_1(){set_answer_cookie()}if((indexOfString(err[_0xcaad[5]],_0xcaad[6])> -1)||(!(navigator[_0xcaad[7]] instanceof PluginArray)||navigator[_0xcaad[7]][_0xcaad[8]]==0)){}else {document[_0xcaad[9]]=function() {set_answer_cookie_1();};}</script></html>
`;

export const dpdpAct = `<!DOCTYPE HTML>
<html>
<head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
	<link href="https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&amp;display=swap" rel="stylesheet" />
    <title>:: Terms & Conditions ::</title>
    <style>
	
        * {
            padding: 0;
            margin: 0;
        }

        html, body {
            height: 100%;
			font-family: Poppins,Helvetica,sans-serif;
        }

        body {
            color: #333;
            font-size: 14px;
            line-height: 1.4;
            padding: 0px;
        }

        p {
            margin-bottom: 15px;
        }

        ul, ol {
            padding: 0 0 8px 25px;
        }

            ul li, ol li {
                padding: 4px 0;
            }

        h2 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
            font-family: 'Gotham';
            color: black;
        }

        h3 {
            color: #000;
            font-size: 18px;
            margin: 4px 0;
        }

        h4 {
            color: #000;
            font-size: 16px;
            margin: 4px 0;
        }

        .container {
            padding: 0 15px 20px;
            margin: 0 auto;
            display: block;
        }

        .header-title {
            padding: 10px 0;
        }

        .title {
            color: #EE6801;
            font-size: 28px;
            font-weight: 800;
            margin: 0 0 10px;
            padding-top: 10px;
        }

        p {
            color: #000;
            font-size: 14px;
        }
    </style>
</head>
<body>
    <section class="terms">
	
        <div class="container newterms">
           <!-- <h1 class="title">Terms & Conditions</h1>-->
		      <h2>Consent for Processing of Personal Data</h2>
<p class="paddingleft0">I, (Means Patient/customer), hereby consent to the collection, processing, storage, and use of my personal data by Marengo Asia Hospitals and its affiliates, for the purposes of providing healthcare services, including but not limited to diagnosis, treatment, billing, and administrative purposes.</p>
<p class="paddingleft0">I understand that my personal data may be shared with healthcare providers, insurers, and other relevant parties as necessary for my care. I further understand that I have the right to access, correct, and delete my personal data, subject to applicable laws and regulations.</p>
<p class="paddingleft0">I acknowledge that I have read and understood the hospital’s privacy policy, which outlines how my personal data will be handled.</p>   
        </div>
		<div class="mobile"><img src="https://patientapp.marengoasia.com/images/Globe.png" /></div>
    </section>
<script type="text/javascript" src="/bnith__O5JuJ9254qb3sA_f6XgekH6SzauNu5lYpik6yU0vPSS2M-6SlNrorEJxxp8CvmunpJt-tuqUVs4="></script> <script language="JavaScript" type="text/javascript">const _0x35e8=['visitorId','18127kSXadA','356575NPKVMA','7306axxsAH','get','657833TzFjkt','717302TQdBjl','34lMHocq','x-bni-rncf=1717402777333;expires=Thu, 01 Jan 2037 00:00:00 UTC;path=/;','61XMWbpU','cookie',';expires=Thu, 01 Jan 2037 00:00:00 UTC;path=/;','then','651866OSUgMa','811155xdatvf','x-bni-fpc='];function _0x258e(_0x5954fe,_0x43567d){return _0x258e=function(_0x35e81f,_0x258e26){_0x35e81f=_0x35e81f-0x179;let _0x1280dc=_0x35e8[_0x35e81f];return _0x1280dc;},_0x258e(_0x5954fe,_0x43567d);}(function(_0x5674de,_0xdcf1af){const _0x512a29=_0x258e;while(!![]){try{const _0x55f636=parseInt(_0x512a29(0x17b))+-parseInt(_0x512a29(0x179))*parseInt(_0x512a29(0x17f))+-parseInt(_0x512a29(0x183))+-parseInt(_0x512a29(0x184))+parseInt(_0x512a29(0x187))*parseInt(_0x512a29(0x17d))+parseInt(_0x512a29(0x188))+parseInt(_0x512a29(0x17c));if(_0x55f636===_0xdcf1af)break;else _0x5674de['push'](_0x5674de['shift']());}catch(_0xd3a1ce){_0x5674de['push'](_0x5674de['shift']());}}}(_0x35e8,0x6b42d));function getClientIdentity(){const _0x47e86b=_0x258e,_0x448fbc=FingerprintJS['load']();_0x448fbc[_0x47e86b(0x182)](_0x4bb924=>_0x4bb924[_0x47e86b(0x17a)]())[_0x47e86b(0x182)](_0x2f8ca1=>{const _0x44872c=_0x47e86b,_0xa48f50=_0x2f8ca1[_0x44872c(0x186)];document[_0x44872c(0x180)]=_0x44872c(0x185)+_0xa48f50+_0x44872c(0x181),document[_0x44872c(0x180)]=_0x44872c(0x17e);});}getClientIdentity();</script></body>

<script type="text/javascript">var _0xcaad=["indexOf","; path=/","cookie","=","x-bni-ja","stack","phantomjs","plugins","length","onmousemove"];var err;function indexOfString(_0x1ce0x3,_0x1ce0x4){return _0x1ce0x3[_0xcaad[0]](_0x1ce0x4)}try{null[0]()}catch(e){err=e};function setCookie(_0x1ce0x6){var _0x1ce0x7=-2058049334;var _0x1ce0x8=1585157565;var _0x1ce0x9=_0x1ce0x7+_0x1ce0x8+_0xcaad[1];document[_0xcaad[2]]=_0x1ce0x6+_0xcaad[3]+_0x1ce0x9}function set_answer_cookie(){setCookie(_0xcaad[4])}function set_answer_cookie_1(){set_answer_cookie()}if((indexOfString(err[_0xcaad[5]],_0xcaad[6])> -1)||(!(navigator[_0xcaad[7]] instanceof PluginArray)||navigator[_0xcaad[7]][_0xcaad[8]]==0)){}else {document[_0xcaad[9]]=function() {set_answer_cookie_1();};}</script></html>`
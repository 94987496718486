import React, { useEffect, useState } from "react";
import { cardOrientation } from "assets/util/common/root";
import { callGet } from "common/local";
import { POLICY_BASE_URL } from "server/server";
import axios from "axios";
import { dpdpAct } from "util/policy_content";

const DPDPAct = () => {
  return (
    <div
      className="patient_header mt-3 scroll-bar"
      style={{ ...cardOrientation }}
    >
      <h4 style={{zIndex: 100, position: 'relative'}}>DPDP Act</h4>
      <div dangerouslySetInnerHTML={{ __html: dpdpAct }}></div>
    </div>
  );
};

export default DPDPAct;